<template>
  <div :class="`m-3 wrapper wrapper--border wrapper--${styling_bu}`">
    <div class="text-center p-2">
      <h2>Resultaten per inkoper</h2>
      <span @click="previousYear"><i class="fas fa-arrow-left"></i></span>
      <span class="text-center m-2">Jaar: {{ year_number }}</span>
      <span @click="nextYear"><i class="fas fa-arrow-right"></i></span>
    </div>
    <Loading v-if="loading" />
    <div v-else>
      <Tabs
      >
        <Tab title="Jaar" active>
          <div class="p-2">
            <h2 class="text-center">Totaal</h2>
            <ResultsTable
              :table_data="buyer_results_per_year"
              :table_data_sum="buyer_results_per_year_sum"
              :table_style="styling_bu"
            />
          </div>
          <div v-if="bu == 20" class="d-flex flex-row">
            <div class="flex-grow-1 p-2">
              <h2 class="text-center">Trucks</h2>
              <TrucksTrailersResultTable
                :table_data="buyer_trucks_results"
                :table_sum_data="buyer_trucks_results_sum"
                :table_style="styling_bu"
              />
            </div>
            <div class="flex-grow-1 p-2">
              <h2 class="text-center">Trailers</h2>
              <TrucksTrailersResultTable
                :table_data="buyer_trailers_results"
                :table_sum_data="buyer_trailers_results_sum"
                :table_style="styling_bu"
              />
            </div>
          </div>
        </Tab>
        <Tab title="Per half jaar" :onOpen="loadHalfYear">
          <Loading v-if="loading_half_year" />
          <div
            v-for="(item, key) in half_year_data"
            :key="key"
            class="p-2"
            v-else
          >
            <h2 class="text-center">{{ item.title }}</h2>
            <ResultsTable
              :table_data="item.data"
              :table_data_sum="item.totals"
              :table_style="styling_bu"
            />
          </div>
        </Tab>
        <Tab title="Per maand" :onOpen="loadYear">
          <Loading v-if="loading_year" />
          <div v-for="(item, key) in month_data" :key="key" class="p-2" v-else>
            <h2 class="text-center">{{ key }}</h2>
            <ResultsTable
              :table_data="item.data"
              :table_data_sum="item.totals"
              :table_style="styling_bu"
            />
          </div>
        </Tab>
      </Tabs>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
import request from "@/functions/request.js";
import ResultsTable from "@/components/ResultsTable.vue";
import { getYearNumber, subtractTime, addTime } from "@/functions/date.js";
import TrucksTrailersResultTable from "@/components/TrucksTrailersResultTable.vue";
import moment from "moment";
import Tabs from "@/components/Tabs/Tabs.vue";
import Tab from "@/components/Tabs/Tab.vue";

export default {
  props: ["bu"],
  components: { Tabs, Tab, Loading, ResultsTable, TrucksTrailersResultTable },
  data: () => ({
    loading: true,
    loading_half_year: true,
    loading_year: true,
    buyer_results_per_year: null,
    buyer_results_per_year_sum: null,
    buyer_trucks_results: null,
    buyer_trucks_results_sum: null,
    buyer_trailers_results: null,
    buyer_trailers_results_sum: null,
    half_year_data: null,
    month_data: null,
    year_number: null,
    full_date: null,
    styling_bu: null,
  }),
  created() {
    this.full_date = moment();
    this.getData(this.bu);
    this.bu == 20 ? this.styling_bu = 200 : this.styling_bu = this.bu;
  },
  watch: {
    bu(newbu) {
      this.loading = true;
      this.loading_half_year = true;
      this.loading_year = true;
      this.getData(newbu);
    },
  },
  methods: {
    getData(bu) {
      if (this.loading) {
        this.year_number = getYearNumber(this.full_date);
        request(`results-per-buyer/${bu}/${this.year_number}`, "GET").then(
          ({
            buyer_results_per_year,
            buyer_trucks_results,
            buyer_trailers_results,
          }) => {
            this.buyer_results_per_year = buyer_results_per_year.data;
            this.buyer_results_per_year_sum = buyer_results_per_year.totals;
            if (this.bu == 20) {
              this.buyer_trucks_results = buyer_trucks_results.data;
              this.buyer_trucks_results_sum = buyer_trucks_results.totals;
              this.buyer_trailers_results = buyer_trailers_results.data;
              this.buyer_trailers_results_sum = buyer_trailers_results.totals;
            }
            this.loading = false;
          }
        );
      }
    },

    loadHalfYear() {
      if (this.loading_half_year) {
        request(
          `results-per-buyer-half-year/${this.bu}/${this.year_number}`,
          "GET"
        ).then(({ half_year_data }) => {
          this.half_year_data = half_year_data;
          this.loading_half_year = false;
        });
      }
    },

    loadYear() {
      if (this.loading_year) {
        request(
          `results-per-buyer-per-month/${this.bu}/${this.year_number}`,
          "GET"
        ).then(({ month_data }) => {
          this.month_data = month_data;
          this.loading_year = false;
        });
      }
    },


    previousYear() {
      this.loading = true;
      this.loading_half_year = true;
      this.loading_year = true;
      this.full_date = subtractTime(1, this.full_date, "years");
      this.getData(this.bu);
    },

    nextYear() {
      this.loading = true;
      this.loading_half_year = true;
      this.loading_year = true;
      this.full_date = addTime(1, this.full_date, "years");
      this.getData(this.bu);
    },
  },
};
</script>
